





















import { Vue, Component, Prop } from 'vue-property-decorator';
import Search from '@/core/support/search/Search';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';

@Component({
  components: {
    InputField,
  },
})
export default class DataTableSearchField extends Vue {
  @Prop() private search!: Search;
  @Prop() private searchOptions?: any; // TODO make some contract

  /**
   * Display getters
   */
  private get displaySearchDropdown(): boolean {
    return !!this.searchOptions;
  }

  private get displayClearButton(): boolean {
    return !this.search.empty;
  }

  /**
   * Handle query
   */
  private get query(): string | null {
    return this.search.queryString;
  }

  private set query(query: string | null) {
    this.search.queryString = query;
  }

  /**
   * Handle field
   */
  private get field(): string | null {
    return this.search.queryField;
  }

  private set field(field: string | null) {
    this.search.queryField = field;
  }

  /**
   * Methods
   */
  private updateSearch() {
    this.$emit('update', this.search);
  }

  /**
   * Handlers
   */
  private onClickClear() {
    this.query = null;

    if (this.searchOptions) {
      this.field = this.searchOptions[0].value;
    }

    this.updateSearch();
  }
}
